.modal-login-requerido-texto {
    width: auto;
    min-height: 32px;
    max-height: none;
    height: auto;
    color: #222;
}

.modal-login-requerido-botones {
    border-width: 1px 0 0 0;
    border-style: solid;
    border-color: #aaaaaa;
    background-image: none;
    margin-top: .5em;
    padding: .9em 1em .5em .4em;
    color: #222222;
    display: flex;
    justify-content: flex-end;
}

.modal-login-requerido-botones button {
    font-family: 'Roboto', Arial, Helvetica, sans-serif!important;
    background-color: #465E89!important;
    border-color: #465E89!important;
    color: #fff!important;
    border-radius: 5px!important;
    font-size: 1em!important;
    margin: .5em .4em .5em 0;
}

.modal-login-requerido-botones button span {
    padding: 0.4em 1em;
}
