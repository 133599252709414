.note {
    color: #666666;
    font-size: 11px !important;
    margin-left: 200px;
    margin-top: 5px;
}

select {
    background-color: white;
}

.topSeparator {
    margin-top: 2rem;
}

.formInputError {
    border-color: red;
}

/* TOOLTIP */
.tooltipText {
    visibility: hidden;
    position: absolute;
    z-index: 1;
    width: 200px;
    color: #414142;
    font-size: 12px;
    background-color: #fff;
    border: 1px solid #888;
    border-radius: 10px;
    padding: 10px 15px 10px 15px;
}

.hoverText:hover .tooltipText {
    visibility: visible;
}

#top {
    top: -40px;
    left: -50%;
}

#bottom {
    top: 25px;
    left: -50%;
}

#left {
    top: -8px;
    right: 120%;
}

.tooltipRight {
    top: -25px;
    left: 40px;
}

.hoverText {
    /*position: relative;
    display: inline-block;
    margin: 40px;*/
    text-align: center;
}