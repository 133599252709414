.containerResumen{
    display: flex;
    justify-content: space-between;
}

.containerResumenRegistros{
    display: flex;
    justify-content: flex-start;
}

.datosContacto {
}

.datosCuentaPago {
}

.note {
    color: #666666;
    font-size: 11px !important;
}