.prom button {
    padding:5px 10px;
    margin-left: 5px;
}

button {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    border: 1px solid #ddd;
    border-radius:5px;
    vertical-align:middle;
    font-size:14px;
    font-weight: normal;
}