.modal {
    display: flex; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(34,34,34,0.8); /* Black w/ opacity */
    border: 1px solid #888;
    border-radius:5px;
}
.link-style-button {
    background: none;
    color: #374b72;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    text-decoration: underline;
}

.link-style-button:hover,
.link-style-button:focus {
    color: darkblue;
    text-decoration: none;
}

.modal-nav-cart {
    top: 0;
    position:absolute;
    width:300px;
    height:auto;
    padding:15px;
    z-index: 1000;
    border-radius:5px;
    -moz-border-radius:5px;
    -webkit-border-radius:5px;
}

/* Modal Content */
.modal-content {
    position: relative;
    background-color: #FFF;
    margin: auto;
    padding: 20px;
    width: auto;
    height: auto;
    color: #414142;
    font-size: 0.9rem;
    -moz-border-radius:5px;
    -webkit-border-radius:5px;
}

/* The Close Button */
.close-modal {
    color: #aaaaaa;
    position: absolute;
    right: 0.2em;
    top: 0.15rem;
    font-size: 28px;
    font-weight: bold;
    background-image: url("./modal-close.png") !important;
    width: 22px;
    height: 22px;
}

.close-modal:hover {
    background-image: url("./modal-close-hover.png") !important;
}


.close-modal:hover,
.close-modal:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.texto-azul {
    color: #465E89;
}