
.modal .btn, .a.btn, .modal .btn:hover, .a.btn:hover {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    margin: 0px;
    padding: 5px;
    font-size: 14px;
    font-weight: normal;
    border: 1px solid #ddd;
    text-decoration: none;
    vertical-align: middle;
}

.modal .btn:hover, .a.btn:hover {
    background: #324770;
}

.modal fieldset {
    width: auto;
    max-width: 840px;
}
.data-domain {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}


.data-domain td {
    padding: 8px;
    border: 1px solid #ddd;
    vertical-align: middle;
    text-align: left;
}


/*.data-domain .link {*/
/*    cursor: pointer;*/
/*    text-decoration: none;*/
/*}*/

/*.data-domain .link:hover {*/
/*    text-decoration: underline;*/
/*}*/
