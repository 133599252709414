
.box-domain input[type=text] {
    margin-right: 0.3em;
}

.box-content ul li {
    list-style-type: none;
}

.dropdown-menu {
    --bs-dropdown-min-width: 0;
}

.dropdown-item {
    width: auto;
}

.dropdown-btn {
    vertical-align: center;
    width: 85px;
    height: 29px;
    background: #efefef;
    border: 1px solid #ddd;
    border-radius: 5px;
    color: black;
    display: table;
    padding: 0;
    font-size: 14px;
}

.dropdown-btn:hover {
    background: #efefef;
    color: black;
}

.dropdown-btn:focus {
    background: #efefef;
    color: black;
}

.domain-tips-inline {
    display: inline-block;
    margin-right: 20px;
}

.checkbox-space {
    margin-right: 6px;
}

.btn:hover {
    background: #324770;
    color: #fff;
    border-color: #000;
    padding: 7px 12px;
    font-weight: normal;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    vertical-align: middle;
}

.btn:focus {
    background: #324770;
    color: #fff;
    padding: 7px 12px;
    font-weight: normal;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    vertical-align: middle;
}