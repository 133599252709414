.steps {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    margin-top: 1rem;
    align-items: center;
}

.step {
    cursor: pointer;
    padding: 8px 20px 5px;
    border-radius: 5px 5px 5px 5px;
    -moz-border-radius: 5px 5px 5px 5px;
    -webkit-border-radius: 5px 5px 5px 5px;
    background: #ededed;
}

.line {
    display: inline-block;
    border-top: 1px dotted black;
    width: 2em;
    flex-grow: 1;
    margin: 0 5px;
}

.step.active {
    border-left: 1px solid #324770;
    border-right: 1px solid #324770;
    background: #324770;
}

.step.active a {
    color: white;
    text-decoration: none;
}

.step a {
    color: black;
    text-decoration: none;
}

.step-content {
    display: none;
}

.note {
    color: #666666;
    font-size: 11px !important;
    margin-left: 200px;
    margin-top: 5px;
}

/*.button {
    height: 30px;
    border: 1px solid #465e89;
    border-radius: 5px;
    background-color: #172c52;
    color: #fff;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    cursor: pointer;
    padding: 3px 5px;
}*/

.button {
    background: #324770;
    color: #fff;
    border-color: #000;
    padding: 4px 6px;
    font-weight: normal;
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
    vertical-align: middle;
    margin-left: 3px;
}

.stepsContainer {
    display: flex;
    justify-content: center;
}

.stepContent {
    width: 100%;
}

.note {
    color: #666666;
    font-size: 11px !important;
    margin-left: 200px;
    margin-top: 5px;
}

select {
    background-color: white;
}

.topSeparator {
    margin-top: 2rem;
}

.formInputError {
    border-color: red;
}

.disabledButton {
    opacity: 0.7;
}

.disabledButton:hover {
    background: #465E89 !important;
    cursor: default !important;
}

.inputReadOnly {
    user-select: none;
    cursor: default;
    outline: none;
    border: none;
}

p.info {
    margin-top: 0.7rem;
}

/*Indicador de cuantos solicitudes por procesar tiene el usuario*/
.notificationItem {
    position: relative;
    /* Other styles for your main element go here */
}

.notificationItem::after {
    content: attr(data-count);
    position: absolute;
    right: -10px;
    top: -10px;
    padding: 5px;
    border-radius: 50%;
    border: 1px solid white;
    background-color: #d45851;
    color: white;
    min-width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 0.9em;
}