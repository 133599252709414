.modal-content {
    padding: 20px;
    background-color: white; /* Ajusta según tu tema */
    border-radius: 8px;
}

.modal-text {
    font-size: 16px; /* Ajusta el tamaño de fuente según necesites */
    margin-bottom: 20px;
}

.modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.btn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
}

.confirm {
    background-color: #4CAF50; /* Verde */
    color: white;
}

.cancel {
    background-color: #f44336; /* Rojo */
    color: white;
}
