
button.btn {
    margin: 0 2px;
}

.modal-be {
    height: auto;
    width: 840px;
}

.linea {
    border-top: 1px solid #AAAAAA;
}