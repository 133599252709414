.site-layout-content {
    min-height: 600px;
    padding: 24px;
}

.logo {
    float: left;
    width: 40px;
    height: 40px;
    margin: 10px 24px 10px 0;
}

.label-admin {
    display: inline-block;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    padding: 4px 8px;
    border-radius: 2px;
    font-weight: 400;
}


.label-admin.label-info {
    background-color: #465E89
}

.label-admin.label-success {
    background-color: #008000
}

.label-admin.label-warning {
    background-color: #FFD600
}

.label-admin.label-danger {
    background-color: #A23647
}

.label-admin.label-outlined {
    border-width: 1px;
    border-style: solid;
    background-color: transparent
}

.label-admin.label-outlined.label-info {
    border-color: #465E89;
    color: #465E89
}

.label-admin.label-outlined.label-success {
    border-color: #008000;
    color: #008000
}

.label-admin.label-outlined.label-warning {
    border-color: #a99210;
    color: #a99210
}

.label-admin.label-outlined.label-danger {
    border-color: #A23647;
    color: #A23647
}


